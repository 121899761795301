import * as React from 'react'
import { SEO } from '../../components/seo'
import { Button } from '../../components/button'
import { Link } from '../../components/link'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row">
            <h1>Button component(s)</h1>
          </div>
        </header>
        <section className="container container--grid">
          <div className="container__row">
            <h2>Buttons</h2>

            <h3>Default appearances (by element)</h3>

            <Button href="/styleguide">Anchor</Button>
            <Button as={Link} to="https://google.com">
              Router Link
            </Button>
            <Button onClick={console.log}>Button</Button>

            <Button appearance="secondary" href="/styleguide">
              Anchor
            </Button>
            <Button appearance="secondary" as={Link} to="/styleguide">
              Router Link
            </Button>
            <Button appearance="secondary" onClick={console.log}>
              Button
            </Button>

            <h3>Sizes</h3>

            <Button size="small" href="/styleguide">
              Anchor
            </Button>
            <Button size="small" as={Link} to="/styleguide">
              Router Link
            </Button>
            <Button size="small" onClick={console.log}>
              Button
            </Button>

            <Button size="small" appearance="secondary" href="/styleguide">
              Anchor
            </Button>
            <Button
              size="small"
              appearance="secondary"
              as={Link}
              to="/styleguide"
            >
              Router Link
            </Button>
            <Button size="small" appearance="secondary" onClick={console.log}>
              Button
            </Button>

            <h3>With directional arrow</h3>

            <Button arrow href="/styleguide">
              Anchor
            </Button>
            <Button arrow as={Link} to="/styleguide">
              Router Link
            </Button>
            <Button arrow onClick={console.log}>
              Button
            </Button>
            <Button appearance="secondary" arrow href="/styleguide">
              Anchor
            </Button>
            <Button appearance="secondary" arrow as={Link} to="/styleguide">
              Router Link
            </Button>
            <Button appearance="secondary" arrow onClick={console.log}>
              Button
            </Button>

            <h3>Stretch to fill</h3>

            <div className="display-flex">
              <div style={{ maxWidth: 420 }}>
                <Button arrow fill href="/styleguide">
                  Anchor
                </Button>
                <Button arrow fill as={Link} to="/styleguide">
                  Router Link
                </Button>
                <Button arrow fill>
                  Button
                </Button>
                <Button appearance="secondary" arrow fill href="/styleguide">
                  Anchor
                </Button>
                <Button
                  appearance="secondary"
                  arrow
                  fill
                  as={Link}
                  to="/styleguide"
                >
                  Router Link
                </Button>
                <Button appearance="secondary" arrow fill>
                  Button
                </Button>
              </div>
              <div style={{ maxWidth: 420 }}>
                <Button fill href="/styleguide">
                  Anchor
                </Button>
                <Button fill as={Link} to="/styleguide">
                  Router Link
                </Button>
                <Button fill>Button</Button>
                <Button appearance="secondary" fill href="/styleguide">
                  Anchor
                </Button>
                <Button appearance="secondary" fill as={Link} to="/styleguide">
                  Router Link
                </Button>
                <Button appearance="secondary" fill>
                  Button
                </Button>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
